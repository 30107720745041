import React from 'react';

import { Magnifier } from 'react-image-magnifiers'

export const Imagen = ({urlIMG}) => {

  let state = {
    previewHorizontalPos: "right",
    previewVerticalPos: "bottom",
    previewSizePercentage: 35,
    previewOpacity: 1,
    shadow: false,
    show: true
  };

  return (
    <>
      <Magnifier
        className='img-img'
        imageSrc={urlIMG}
        largeImageSrc={urlIMG}
      >

      </Magnifier>
    </>
  )
};
