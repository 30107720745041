import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const URL_SRV_RAIZ = "https://apimedidores.apidev.info/"

export const Buscar = ({metadata}) => {

  const [testo, setTesto] = useState("");
  const [tipe_, setTipe_] = useState("DESC");
  const [Lists_, setLists_] = useState([]);

  const navigate = useNavigate();

  const FindMiData = async (e) => {
    e.preventDefault()
    if(testo !== "" || testo.length <= 3 || tipe_ !== ""){
      // console.log("Buscalo ",tipe_, testo);

      const query = await fetch(`${URL_SRV_RAIZ}buscalo_po/${tipe_}/${testo.toLocaleUpperCase()}`)

      const reqkkst = await query.json();
      // console.log('el rutttt=>>>>>>>',reqkkst.data.body)
      if(reqkkst.data.body.length){
        setLists_(reqkkst.data.body)
      }else{
        setLists_([])
      }
    }
    
  }

  const FormatNumeral = (number) => {
    //console.log('||=>', number)
    if(number==="") return ""
    let numero = new Intl.NumberFormat('es-CL').format(number);
    //return numero.replace(/,/g,'.')
    return numero
  };

  return (
    <>
      <div className='container-fluid bg-header h-shadow'>
        <div className='row'>
          <div className='col-9 header-nav'>
            <span className='text-warning font-italic title text-shadow py-5'>INVENTARI0</span>
            <span className='user-name text-white font-italic'>Usuario:{metadata.user}</span><br/>
          </div>
          <div className='col-3'>
            <button className="btn btn-sm btn-warning mt-3" onClick={()=>navigate('/')}>🔙</button>
          </div>
        </div>
      </div>

      <div className='container fondo-div mt-3 py-3'>
        <form onSubmit={(e)=>FindMiData(e)}>
          <div className='row'>
            <div className='col-8'>
              <TextField type="search" fullWidth label="Buscar" id="fullWidth" size='small' onChange={(e)=>setTesto(e.target.value)} />
            </div>
            <div className='col-4'>
              <button className='btn btn-success w-100'>Buscar</button>
            </div>
            <div className='col-12 text-center'>
              <FormControl size='small'>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  defaultValue="DESC"
                  onChange={(e)=>setTipe_(e.target.value)}
                >

                  <FormControlLabel
                    value="DESC"
                    control={<Radio size='small' />}
                    label="DESCRIPCION"
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    value="CODIGO"
                    control={<Radio size='small' />}
                    label="CODIGO"
                    labelPlacement="end"
                  />
                  
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </form>
      </div>

      <div className='container fondo-div mt-3 py-3'>
        <div className='row'>
          <div className='col-12 table-responsive'>
          <table className="table table-dark table-bordered table-sm table-hover">
            <thead>
              <tr>
                <th scope="col"><small>CODIGO</small></th>
                <th scope="col"><small>DESCRIPCION</small></th>
                <th scope="col"><small>UBICACION</small></th>
                <th scope="col"><small>PRECIO</small></th>
                <th scope="col"><small>SALDO</small></th>
              </tr>
            </thead>
            <tbody>
            {
              Lists_.length?
              Lists_.map(itm=>
                <tr key={itm.CODIGO}>
                  <td><small>{itm.CODIGO}</small></td>
                  <td><small>{itm.DESC_CODIGO}</small></td>
                  <td className='text-center'><small>{itm.UBICACION}</small></td>
                  <td><small>${FormatNumeral(itm.PRECIO_MEDIO)}</small></td>
                  <td className='text-center'><small>{itm.SALDO}</small></td>
                </tr>
              ):<tr><td colSpan="5" className='text-center'>No se encontraron Coincidencias 😋</td></tr>
            }
              
            </tbody>
          </table>
            
          </div>
        </div>
      </div>
    </>
  )
}
