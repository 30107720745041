let url = "https://contador.apidev.info/api"
let URL_SERVER_MAIN = "https://apimedidores.apidev.info"

// let URL_SERVER_MAIN = "http://localhost:4088"
// let url = "http://localhost:4081/api"


module.exports = {
  url,URL_SERVER_MAIN
}
