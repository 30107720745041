import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { AuthContext } from '../App';
import {url} from '../ApiUrl/Url.var';
import { MenuItem } from '@mui/material';

export default function Login() {
  const [DbNameValue, setDbNameValue] = useState('');
  // console.log(url);
  const [ItemsValues, setItemsValues] = useState({
    user: '',
    clave: '',
  });
  const [valores, setValores] = useState({
    showPassword: false,
    isSubmit: false,
    erroMessage: null
  });
  const [valueSelectDbNmae, setValueSelectDbNmae] = useState([]);

  const [IsComplete, setIsComplete] = useState(true)
  useEffect(() => {
    // console.log(ItemsValues,DbNameValue)
    if(ItemsValues.clave === "" || ItemsValues.user === "" || DbNameValue === ''){
      setIsComplete(true)
    }else{
      setIsComplete(false)
    }
  }, [ItemsValues,DbNameValue])
  

  const HandleSetValores = async (e) => {
    return setItemsValues({...ItemsValues, [e.target.name]:e.target.value})
  }

  const handleChange = (prop) => (event) => {
    setValores({ ...valores, [prop]: event.target.value });
  };
  
  const handleClickShowPassword = () => {
    setValores({
      ...valores,
      showPassword: !valores.showPassword,
    });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  

  const {dispatch} = React.useContext(AuthContext);

  const HandleFormSubmit = async (e) => {
    const fetchlogin = await fetch(url+'/sigin',{
      method: 'POST',
      headers: {
        'authorization': "paico 2021",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: ItemsValues.user,
        clave: ItemsValues.clave,
        dbname: DbNameValue
      })
    })

    const response = await fetchlogin.json()
    // console.log(response.data.metadata);
    if(response.data.error)setValores({...valores,erroMessage: response.data.error})
    else{
      dispatch({
        type: 'LOGIN',
        payload: {
          user: response.data.metadata.user,
          token: response.data.metadata.token,
          dbname:response.data.metadata.dbname,
          emp_code:response.data.metadata.emp_code
        }
      })
    }

  }
  useEffect(() => {
    const HandleGetData = async () => {
      // console.log('getData');
      const fetchlogin = await fetch(url+'/getdbname',{
        method: 'POST',
        headers: {
          'authorization': "ariztia2025",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
  
      const response = await fetchlogin.json()
      // console.log(response.data.body);
      setValueSelectDbNmae(response.data.body)
      return
    }
    HandleGetData()
  }, []);
  

  return (
    <div className="container">
    <div className="row" style={{height: '100vh'}}>
      <div className="col-12 col-md-6 d-flex align-items-center text-center justify-content-center">
        <img src="/img/logo.svg" alt="Ariztia" className="w-50 t-shadow" />
      </div>
      <div className="col-12 col-md-6 px-3 fondo d-flex align-items-center text-center justify-content-center" >
        <div className="container-fluid">
          <div className="row fondo-div py-5">
            <div className="col-12 my-2 ">
            <TextField
              id="outlined-select-currency"
              select
              label="Seleccione Base de Datos"
              value={DbNameValue}
              onChange={(e)=>setDbNameValue(e.target.value)}
              name='planta'
              fullWidth
              size='small'
            >
              {
                valueSelectDbNmae.map((itm, index) =>
                  <MenuItem key={index} value={itm._id}>{itm._id}</MenuItem>
                )
              }
            </TextField>
            </div>
            <div className="col-12 my-2 ">
              <TextField
                type={"password"}
                id="outlined-basic"
                fullWidth
                label="Clave"
                variant="outlined"
                size='small'
                name='clave'
                value={ItemsValues.password}
                onChange={(e)=>HandleSetValores(e)}
              />
            </div>
            <div className="col-12 my-2 ">
              <TextField
                id="outlined-basic"
                fullWidth
                label="Ingrese su Nombre"
                variant="outlined"
                size='small'
                name='user'
                value={ItemsValues.user}
                onChange={(e)=>setItemsValues({...ItemsValues, [e.target.name]:e.target.value})}
              />
              {
                valores.erroMessage?
                <span className='text-danger'>{valores.erroMessage}</span>
                :null
              }
            </div>
            <div className="col-12 mt-3">
              <Button
                disabled={IsComplete}
                variant='contained'
                fullWidth size='small'
                onClick={()=>HandleFormSubmit()}
              >
                Entrar al Inventario
              </Button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  );
}
