import React, { useEffect, useReducer } from "react";
import Login from "./startSession/Login.view";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Inventario from "./view/Inventario.view";
import { Buscar } from "./view/Buscar.view";

export const AuthContext = React.createContext()

const initialSatate = {
  isAuthenticated: false,
  user: null,
  token: null,
  dbname: null,
  emp_code: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      sessionStorage.setItem('user', action.payload.user)
      sessionStorage.setItem('token', action.payload.token)
      sessionStorage.setItem('dbname', action.payload.dbname)
      sessionStorage.setItem('emp_code', action.payload.emp_code)
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        dbname: action.payload.dbname,
        emp_code: action.payload.emp_code
      }
    
    case 'LOGOUT':
      sessionStorage.clear()
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        dbname: null,
        emp_code: null
      }
  
    default:
      return state
  }
}

function App() {

  const [state, dispatch] = useReducer(reducer, initialSatate);

  useEffect(() => {
    const token = sessionStorage.getItem('token') || null
    const user = sessionStorage.getItem('user') || null
    const dbname = sessionStorage.getItem('dbname') || null
    const emp_code = sessionStorage.getItem('emp_code') || null
    if(token){
      dispatch({
        type: 'LOGIN',
        payload: {
          token,
          user,
          dbname,
          emp_code
        }
      })
    }
  }, []);

  if(!state.token){
    return (
      <AuthContext.Provider
        value={{state, dispatch}}
      >
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='*' element={<Login />} />
          </Routes>
        </BrowserRouter>

      </AuthContext.Provider>
    )
  } else {
    return (
      <AuthContext.Provider
        value={{state, dispatch}}
      >
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Inventario metadata={state} />} />
            <Route path='/buscar' element={<Buscar metadata={state} />} />
          </Routes>
        </BrowserRouter>
      </AuthContext.Provider>
    )
  }
}

export default App;
