import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { URL_SERVER_MAIN, url } from '../ApiUrl/Url.var';
import { Imagen } from './Imagen.component';
import './inventary.css'
import { useNavigate } from 'react-router-dom';

export default function Inventario({metadata}) {
 
  const [DataResponse, setDataResponse] = useState({
    DESC_CODIGO: '',
    SALDO: '',
    PRECIO_MEDIO: 0,
    UBICACION: ''
  })

  const [isFindCode, setisFindCode] = useState(true)
  
  const [UrlImagen, setUrlImagen] = useState('');

  const navigate = useNavigate();

  // console.log(metadata);
  const [TheCode, setTheCode] = useState({
    bodega: '128',
    codigo: '',
    code_emp: metadata.emp_code,
    user: metadata.user,
    ctrl_stock: false,
    isimg: true
  })

  /** Datos que se envian a guardar por item inventariado */
  const [DataMedia, setDataMedia] = useState({
    'contados': ''
  });
  const [AntCode, setAntCode] = useState(null);

  const HandleFindCode = async () => {
    await setDataResponse({...DataResponse,["UBICACION"]:''})
    await setUrlImagen('')
    const fetchlogin = await fetch(url+'/getdatacode',{
      method: 'POST',
      headers: {
        'authorization': metadata.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: TheCode,
      })
    })

    const response = await fetchlogin.json()
    // console.log(response.data.body);
    if(!response.data.success){
      sessionStorage.clear()
      window.location.href = '/'
    }
    setUrlImagen(response.data.body.urlPhoto)
      await setDataResponse({
      DESC_CODIGO: response.data.body.buscaCode.DESC_CODIGO,
      SALDO: response.data.body.buscaCode.SALDO,
      PRECIO_MEDIO: response.data.body.buscaCode.PRECIO_MEDIO,
      UBICACION: response.data.body.buscaCode.UBICACION
    })
    setisFindCode(response.data.body.buscaCode.DESC_CODIGO==="CODIGO NO ENCONTRADO"?false:true)
  }
  const sentToChangeUbication = async (data) => {
    // console.log(URL_SERVER_MAIN+'/changemiunication');
    await fetch(URL_SERVER_MAIN+'/changemiunication',{
      method: 'POST',
      headers: {
        'authorization': metadata.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data
      })
    })
    return
  }

  /** envia los datos del item invetariado para guardarlo */
  const HandleSaveCode = async () => {
    if(TheCode.codigo === '' || DataMedia.contados === ''){
      window.alert('Primero debes Buscar un CODIGO de ARTICULO y despues ingresar la catidad CONTADA!!')
    }else{
      let data = {TheCode, DataResponse, conteo: DataMedia.contados}
      sentToChangeUbication(data)
      const fetchlogin = await fetch(url+'/savecode',{
        method: 'PUT',
        headers: {
          'authorization': metadata.token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data
        })
      })

  
      const response = await fetchlogin.json()
      // console.log(response.data.body);
      if(!response.data.success){
        sessionStorage.clear()
        window.location.href = '/'
      }
      setTheCode({
        bodega: '128',
        codigo: '',
        code_emp: metadata.emp_code,
        user: metadata.user,
        ctrl_stock: false,
        isimg: true
      })
      setDataResponse({
        DESC_CODIGO: '',
        SALDO: 0,
        PRECIO_MEDIO: 0,
        UBICACION: ''
      })
      setDataMedia({
        'contados': ''
      })
      setUrlImagen('')
      sessionStorage.setItem('AntCode',response.data.body.AntCode+" => "+DataResponse.UBICACION+"("+DataMedia.contados+")")
      setAntCode(response.data.body.AntCode+" => "+DataResponse.UBICACION+"("+DataMedia.contados+")")
      setisFindCode(true)
    }
   
  }

  useEffect(() => {
    let theCodeIsHere = sessionStorage.getItem('AntCode') || null
    setAntCode(theCodeIsHere)
  }, [AntCode]);
  
  const  HandleDownloadFile = async () => {
    window.location.href = url+'/dejamelocaer'
  }

  return (
    <>
      <div className='container-fluid bg-header h-shadow'>
        <div className='row'>
          <div className='col-9 header-nav'>
            <span className='text-warning font-italic title text-shadow py-5'>INVENTARI0</span>
            <span className='user-name text-white font-italic'>Usuario:{metadata.user}</span><br/>
            {/* navigate('/cambiar_direccion') */}
          </div>
          <div className='col-3'>
            <FormControl className="text-warning p-0 m-0 float-right">
              <RadioGroup
                className="p-0 m-0"
                defaultValue="128"
                aria-labelledby="demo-form-control-label-placement"
                name="bodega"
                onChange={(e)=>{setTheCode({...TheCode,[e.target.name]: e.target.value})}}
              >
                <FormLabel className="text-warning p-0 m-0">Bodega</FormLabel>
                <FormControlLabel
                  className="p-0 m-0"
                  value="128"
                  control={<Radio size='small' color='warning' className="p-0 m-0"/>}
                  label="128"
                  labelPlacement="start"
                />
                
                <FormControlLabel
                className="p-0 m-0"
                  value="L04"
                  control={<Radio size='small' color='warning' className="p-0 m-0" />}
                  label="L04"
                  labelPlacement="start"
                  
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='col-12'>
            <span className='btn btn-sm btn-warning' onClick={()=>navigate('/buscar')}>Buscar 🔎</span>
          </div>
        </div>
      </div>

      <div className='container mt-3 py-3 pb-4 fondo-div'>
        <div className='row'>
          <div className='col-8 col-md-9 py-2'>
            <TextField
              type={'number'}
              label="Codigo"
              variant="outlined"
              size='small'
              fullWidth
              name='codigo'
              value={TheCode.codigo}
              onChange={(e)=>{setTheCode({...TheCode, [e.target.name]: e.target.value})}}
              helperText={
                AntCode?`Codigo Anterior:${AntCode}`:null
              }
            />
          </div>
          <div className='col-4 col-md-3 py-2 text-center'>
            <Button
              variant='contained'
              onClick={()=>HandleFindCode()}
            >
              Buscar
            </Button>
          </div>
              {/* RESPONSE */}
          <div className='col-12 col-md-8 py-2'>
            <TextField
              label="Descripcion"
              variant="outlined"
              size='small'
              fullWidth
              disabled={isFindCode}
              value={DataResponse.DESC_CODIGO}
              onChange={(e)=>setDataResponse({...DataResponse,["DESC_CODIGO"]:String(e.target.value).toUpperCase()})}
            />
          </div>
          <div className='col-6 col-md-2 py-2'>
            <TextField
              label="Saldo"
              variant="outlined"
              size='small'
              fullWidth
              disabled
              value={DataResponse.SALDO}
              focused
            />
          </div>
          <div className='col-6 col-md-2 py-2'>
            <TextField
              label="Ubicacion"
              variant="outlined"
              size='small'
              fullWidth
              onChange={(e)=>setDataResponse({...DataResponse,["UBICACION"]:String(e.target.value).toUpperCase()})}
              value={DataResponse.UBICACION}
              focused
            />
          </div>
          <div className='col-12 col-md-4 py-2'>
            <TextField
              type={'number'}
              label="Contados"
              variant="outlined"
              size='small'
              fullWidth
              value={DataMedia.contados}
              name='contados'
              onChange={(e)=>setDataMedia({...DataMedia, [e.target.name]: e.target.value})}
            />
          </div>
          <div className='col-12 col-md-8 pt-2 text-center'>
            <FormControlLabel
              className="p-0 m-0 bor-imit mr-2 px-2"
              control={<Switch name='ctrl_stock'
              onChange={(e)=>setTheCode({
                ...TheCode,[e.target.name]:e.target.checked})
              } 
              checked={TheCode.ctrl_stock} 
              color='warning' />}
              label="Sin Control de Stock" />
            <FormControlLabel
              className="p-0 m-0 bor-imit px-2"
              control={<Switch name='isimg'
              onChange={(e)=>setTheCode({
                ...TheCode,[e.target.name]:e.target.checked})} checked={TheCode.isimg} color='success' />
                }
                label="La imagen Coincide con el Articulo?" />
          </div>
          <div className='col-12 pt-2 text-center'>
            <Button fullWidth variant='contained' color='success' onClick={()=>HandleSaveCode()}>Guardar Conteo</Button>
          </div>
          {/* <span>"{UrlImagen}"S</span> */}
            
        </div>
      </div>
      <div className='container'>
          <div className='d-flex justify-content-center my-3 resp-img'>
            <div className='col-12 col-md-8'>
              {
                UrlImagen!==""?<Imagen urlIMG={UrlImagen} />:<br/>
              }
            </div>
            
            
            {/* <img src="http://200.35.158.174:4081/api/getfoto/206080" alt="ddddddddd" className='w-100'/> */}
          </div>
        <div className='col-12 my-2 text-center'>
          <Button variant='contained' color='secondary' onClick={()=>HandleDownloadFile()}>Descar Archivo de Inventario</Button>
        </div>
      </div>
      {/* {metadata.user}<br />{metadata.dbname} */}
      
    </>
  )
}
